export const basic = {
  aside: "bg-gray-800",
  asideScrollbars: "aside-scrollbars-gray",
  asideBrand: "bg-gray-900 text-white",
  asideMenuItem: "text-white hover:text-white",
  asideMenuItemActive: "font-bold text-white",
  asideMenuDropdown: "bg-gray-700/50",
  navBarItemLabel: "text-black",
  navBarItemLabelHover: "hover:text-blue-500",
  navBarItemLabelActiveColor: "text-blue-600",
  overlay: "from-gray-700 via-gray-900 to-gray-700",
};
export const white = {
  aside: "bg-gray-800",
  asideScrollbars: "aside-scrollbars-gray",
  asideBrand: "bg-gray-900 text-white",
  asideMenuItem: "text-white hover:text-white",
  asideMenuItemActive: "font-bold text-white",
  asideMenuDropdown: "bg-gray-700/50",
  navBarItemLabel: "text-black",
  navBarItemLabelHover: "hover:text-blue-500",
  navBarItemLabelActiveColor: "text-blue-600",
  overlay: "from-gray-700 via-gray-900 to-gray-700",
};

// export const white = {
//   aside: "bg-white",
//   asideScrollbars: "aside-scrollbars-dark",
//   asideBrand: "",
//   asideMenuItem: "text-white hover:text-blue-500 dark:text-white",
//   asideMenuItemActive: "font-bold text-blue-500 dark:text-white",
//   asideMenuDropdown: "bg-gray-100/75",
//   navBarItemLabel: "text-blue-600",
//   navBarItemLabelHover: "hover:text-black",
//   navBarItemLabelActiveColor: "text-black",
//   overlay: "from-white via-gray-100 to-white",
// };
